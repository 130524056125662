import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import jwt_decode from "jwt-decode";
import VueCookies from "vue-cookies";
import api from "@/axios";
import osintApi from "@/axios/osint";
import {blackboxApi, lightrayApi, iamApi, caseApi, userApi, jwtBlackboxApi, osintDataUrl, riskApi} from "@/main";
import {getParamsFromUrl} from "../views/login/services";

// import { validateToken } from "@/services/auth";

import JWT from "jsonwebtoken";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
    // You can set your default options here
};
import {fetchRefreshToken, fetchWlpRefreshToken} from "@/utils/functions";
import {decodeToken, validateTokenLocal, validateTokenRemote} from "../utils/auth";
import research from "../views/research";

Vue.use(Toast, options);
Vue.use(VueRouter);

const routes = [
    {
        path: "/osint-graph",
        name: "OsintGraph",
        component: () => import(/* webpackChunkName: "OsintGraph" */ "../views/osint-graph-view"),
    },
    {
        path: "/de",
        name: "DocumentEditor",
        component: () => import(/* webpackChunkName: "de" */ "../views/report-builder/documentEditor"),
    },
    {
        path: "/",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "Dashboard" */ "../views/dashboard"),
    },
    {
        path: "/ro/docbrowser/:id",
        name: "docBrowser-ro",
        props: {
            readOnly: true,
        },
        component: () => import(/* webpackChunkName: "docBrowser" */ "../containers/docbrowser"),
    },
    {
        path: "/docbrowser/:id",
        name: "docBrowser",
        props: {
            readOnly: false,
        },
        component: () => import(/* webpackChunkName: "docBrowser" */ "../containers/docbrowser"),
    },
    {
        path: "/automated/research/:id",
        name: "case-automated",
        component: () => import(/* webpackChunkName: "case" */ "../views/research/automation"),
        props: {
            readOnly: false,
        },
        redirect: "/automated/research/:id/case/personalDetails",
        children: [
            {
                // Tools will be rendered inside Research's <router-view>
                // when /research/:id/entities is matched
                path: "entities",
                name: "Entities-automated",
                component: () => import(/* webpackChunkName: "create-entities" */ "../views/cases/entities"),
                redirect: "entities/input",
                children: [
                    {
                        path: "input",
                        name: "entities-input",
                        meta: {isSearchDisabled: true, automated: true},
                    },
                    {
                        path: "output",
                        name: "entities-output",
                        meta: {isSearchDisabled: true, automated: true},
                    },
                ],
            },
            {
                path: "case/:casename?/:caseid?",
                name: "research-automated",
            },
        ],
    },
    {
        path: "/research/:id",
        name: "case",
        component: research,
        props: {
            readOnly: false,
        },
        redirect: "/research/:id/case/personalDetails",
        children: [
            {
                // Tools will be rendered inside Research's <router-view>
                // when /research/:id/entities is matched
                path: "entities",
                name: "Entities",
                component: () => import(/* webpackChunkName: "create-entities" */ "../views/cases/entities"),
                redirect: "entities/input",
                children: [
                    {
                        path: "input",
                        name: "entities-input",
                        meta: {isSearchDisabled: true},
                    },
                    {
                        path: "output",
                        name: "entities-output",
                        meta: {isSearchDisabled: true},
                    },
                ],
            },
            {
                // Tools will be rendered inside Research's <router-view>
                // when /research/:id/case/:casename?/:caseid? is matched
                path: "case/:casename?/:caseid?",
                name: "research",
            },
            {
                // Tools will be rendered inside Research's <router-view>
                // when /research/:id/tools/:toolName? is matched
                path: "tools/:toolName?",
                name: "tools",
                component: () => import(/* webpackChunkName: "tools" */ "../views/research/tools"),
            },
            {
                // monitoring will be rendered inside Research's <router-view>
                // when /research/:id/monitoring/:toolName? is matched
                path: "monitoring/:toolName?",
                name: "monitoring",
                component: () => import(/* webpackChunkName: "tools" */ "../views/research/tools"),
                props: {
                    monitoring: true,
                },
            },
            {
                // methodology will be rendered inside Research's <router-view>
                // when /research/:id/methodology is matched
                path: "methodology",
                name: "methodology",
                component: () => import(/* webpackChunkName: "methodology" */ "../views/research/analysisTools"),
            },
            {
                // sources will be rendered inside Research's <router-view>
                // when /research/:id/sources is matched
                path: "sources",
                name: "sources",
                component: () => import(/* webpackChunkName: "sources" */ "../views/sources"),
            },
            {
                path: "*",
                redirect: "/research/:id/case/personalDetails",
            },
        ],
    },
    {
        path: "/ro/:id",
        name: "read-only",
        component: research,
        props: {
            readOnly: true,
        },
        redirect: "/ro/:id/case/personalDetails",
        children: [
            {
                path: "entities",
                name: "Entities",
                component: () => import(/* webpackChunkName: "create-entities" */ "../views/cases/entities"),
                redirect: "entities/input",
                children: [
                    {
                        path: "input",
                        name: "entities-input",
                        meta: {isSearchDisabled: true},
                    },
                    {
                        path: "output",
                        name: "entities-output",
                        meta: {isSearchDisabled: true},
                    },
                ],
            },
            {
                path: "case/:casename?",
                name: "research",
            },
            {
                path: "case/:casename?/:caseid?",
                name: "research-ro",
            },
            {
                // methodology will be rendered inside Research's <router-view>
                // when /research/:id/methodology is matched
                path: "methodology",
                name: "methodology-ro",
                component: () => import(/* webpackChunkName: "methodology" */ "../views/research/analysisTools"),
            },
            {
                // sources will be rendered inside Research's <router-view>
                // when /research/:id/sources is matched
                path: "sources",
                name: "sources-ro",
                component: () => import(/* webpackChunkName: "sources" */ "../views/sources"),
            },
            {
                path: "tools/:toolName?",
                name: "tools-ro",
                component: () => import(/* webpackChunkName: "tools" */ "../views/research/tools"),
            },
            {
                // Tools will be rendered inside Research's <router-view>
                // when /research/:id/monitoring/:toolName? is matched
                path: "monitoring/:toolName?",
                name: "monitoring-ro",
                component: () => import(/* webpackChunkName: "tools" */ "../views/research/tools"),
                props: {
                    monitoring: true,
                    readOnly: true,
                },
            },

            {
                path: "*",
                redirect: "/ro/:id/case/personalDetails",
            },
        ],
    },
    {
        path: "/research/:id/map",
        name: "Map",
        component: () => import(/* webpackChunkName: "Map" */ "../views/map"),
        props: {
            readOnly: false,
        },
    },
    {
        path: "/ro/:id/map",
        name: "Map-ro",
        component: () => import(/* webpackChunkName: "Map" */ "../views/map"),
        props: {
            readOnly: true,
        },
    },
    {
        path: "/graph",
        name: "Graph",
        component: () => import(/* webpackChunkName: "Graph" */ "../views/graph"),
    },
    {
        path: "/case",
        name: "Cases",
        component: () => import(/* webpackChunkName: "cases" */ "../views/cases"),
    },
    // {
    //     // Case level settings
    //     path: "/case/configure",
    //     name: "Configure Case",
    //     component: () => import(/* webpackChunkName: "caseSettings" */ "../views/research/caseSettings"),
    // },
    // {
    //     // Case level settings
    //     path: "/product/configure",
    //     name: "Configure Product",
    //     component: () => import(/* webpackChunkName: "research" */ "../views/research/caseSettings"),
    // },
    {
        path: "/product",
        name: "Products",
        meta: {isSearchDisabled: true},
        component: () => import(/* webpackChunkName: "product" */ "../views/product-admin"),
    },
    {
        path: "/product/configure",
        name: "Configure Product",
        meta: {isSearchDisabled: true},
        component: () => import(/* webpackChunkName: "product-configure" */ "../views/product-configure"),
    },
    {
        path: "/case/product",
        name: "Case Products",
        component: () => import(/* webpackChunkName: "cases-products" */ "../views/cases/products"),
    },
    {
        path: "/case/create",
        name: "Create Case",
        component: () => import(/* webpackChunkName: "create-case" */ "../views/cases/create-case"),
    },
    // {
    //     path: "/case/:name/entities",
    //     name: "Entities",
    //     component: () => import(/* webpackChunkName: "create-entities" */ "../views/cases/entities"),
    //     redirect: "/case/:name/entities/input",
    //     children: [
    //         {
    //             path: "input",
    //             name: "entities-input",
    //         },
    //         {
    //             path: "output",
    //             name: "entities-output"
    //         },
    //     ]
    // },
    // {
    //     path: "/case/:id",
    //     name: "Case Details",
    //     component: () => import(/* webpackChunkName: "case-details" */ "../views/case-details"),
    //     props: true,
    //     // async beforeEnter(to, from, next) {
    //     //   try {
    //     //     const exists = await store.getters.getCaseData(to.params.id);
    //     //     if (exists) {
    //     //       next();
    //     //     }
    //     //   } catch (e) {
    //     //     next({
    //     //       name: "cases",
    //     //     });
    //     //   }
    //     // },
    // },
    {
        path: "/fields",
        name: "Fields",
        component: () => import(/* webpackChunkName: "fields" */ "../views/fields/field"),
    },
    {
        path: "/monitor",
        name: "Monitor",
        component: () => import(/* webpackChunkName: "monitor" */ "../views/monitor"),
    },
    {
        path: "/reports",
        name: "Reports",
        component: () => import(/* webpackChunkName: "reports" */ "../views/reports"),
    },
    {
        path: "/onboarding",
        name: "Onboarding",
        component: () => import(/* webpackChunkName: "onboarding" */ "../views/onboarding"),
        redirect: "/onboarding/tnc",
        children: [
            {
                path: "tnc",
                name: "Terms and conditions",
            },
            {
                path: "welcome",
                name: "Welcome",
            },
        ],
    },
    {
        path: "/credit-packs",
        name: "Credit Packs",
        meta: {isSearchDisabled: true},
        component: () => import(/* webpackChunkName: "org-users" */ "../views/credit-packs"),
    },
    {
        path: "/settings",
        name: "Settings",
        component: () => import(/* webpackChunkName: "settingst" */ "../views/settings"),
    },
    {
        path: "/create-report",
        name: "Create Report",
        component: () => import(/* webpackChunkName: "report-builder" */ "../views/report-builder"),
    },
    {
        path: "/editor",
        name: "Editor",
        component: () => import(/* webpackChunkName: "editor" */ "../views/report-builder/editor.vue"),
    },
    {
        path: "/template/:tempId",
        name: "Template",
        component: () => import(/* webpackChunkName: "singleTemplate" */ "../views/report-builder/singleTemplate.vue"),
    },
    {
        path: "/template",
        name: "Template Creation",
        component: () => import(/* webpackChunkName: "template-creation" */ "../views/report-builder/template.vue"),
    },
    {
        path: "/admin",
        name: "Admin",
        component: () => import(/* webpackChunkName: "admin-settings" */ "../views/settings"),
    },
    {
        path: "/products",
        name: "Product",
        component: () => import(/* webpackChunkName: "views-product" */ "../views/product"),
    },
    {
        path: "/workflows",
        name: "Workflows",
        component: () => import(/* webpackChunkName: "workflows" */ "../views/workflow"),
    },
    {
        path: "/org-users",
        name: "Users",
        meta: {isSearchDisabled: true},
        component: () => import(/* webpackChunkName: "org-users" */ "../views/users"),
    },
    {
        path: "/cf",
        name: "caseForm",
        component: () => import(/* webpackChunkName: "case-form" */ "../views/caseForm"),
    },
    {
        path: "/groups",
        name: "Groups",
        component: () => import(/* webpackChunkName: "view-groups" */ "../views/groups"),
    },
    {
        path: "/sources",
        name: "Sources",
        component: () => import(/* webpackChunkName: "sources" */ "../views/sources"),
    },
    {
        path: "/research-methodology",
        name: "Research Methodology",
        component: () => import(/* webpackChunkName: "company-methodology" */ "../views/company-methodology"),
    },
    {
        path: "*",
        name: "Not Found",
        component: () => import("../views/not-found"),
    },
    {
        path: "/callback",
        name: "LoginCallback",
        component: () => import("../views/login-callback"),
    },
    // {
    //     path: "/login",
    //     name: "Login",
    //     component: () => import("../auth"),
    // },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/login"),
    },
    {
        // http://localhost:8081/redirects?case_id=QAT-21&route_type=research?code=931d0da7-8284-4517-a212-00cc70b54a75
        path: "/redirects/:case_id?/:route_type?/:verifying_entity_id?/:code?",
        name: "Redirect",
        component: () => import("../views/redirect"),
    },
    {
        path: "/otp-login",
        name: "otp-login",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "../views/login/otpScreen"),
        meta: {
            title: "otp-login",
        },
    },
    {
        path: "/update-password",
        name: "update-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "../views/login/passwordUpdate"),
        meta: {
            title: "update-password",
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import(/* webpackChunkName: "dashboard-page1" */ "../views/login/forgotPasswordPage"),
        meta: {
            title: "forgot-password",
        },
    },
    {
        path: "/case-list",
        name: "View Cases",
        meta: {isSearchDisabled: true},
        component: () => import("../views/case-list"),
    },
    {
        path: "/case-dashboard",
        name: "Case Dashboard",
        meta: {isSearchDisabled: true},
        component: () => import("../views/case-dashboard"),
        // component: () => import("../views/bi-dashboard/bi-dashboard"),
    },
    {
        path: "/default-dashboard",
        name: "Case Dashboard",
        meta: {isSearchDisabled: true},
        component: () => import("../views/case-dashboard"),
    },

    {
        path: "/bi-dashboard-view/:id",
        name: "Bi-Dashboard-View",
        meta: {isSearchDisabled: true},
        component: () => import("../views/bi-dashboard/bi-dashboard-view"),
    },
    // {
    //     path: "/user-list",
    //     name: "Users ",
    //     component: () => import("../views/users-view"),
    // },
    {
        path: "/tool-secret-manager",
        name: "Tool Manager",
        component: () => import("../views/tool-secret"),
    },
    {
        path: "/manage-keywords",
        name: "Manage keywords",
        meta: {isSearchDisabled: true},
        component: () => import("../views/manage-keywords"),
    },
    {
        path: "/report-templates",
        name: "Report Templates",
        meta: {isSearchDisabled: true},
        component: () => import("../views/report-templates-admin"),
    },
    {
        path: "/search-engine",
        name: "Search Engine",
        meta: {isSearchDisabled: true},
        component: () => import("../views/search-engine-admin"),
    },
    {
        path: "/report-builder",
        name: "Output Templates",
        meta: {isSearchDisabled: true},
        component: () => import("../views/report-builder-admin"),
    },
    {
        path: "/dynamic-form",
        component: () => import("../views/DynamicForm"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;

let timer = "";
let endpoints = [api, osintApi, lightrayApi, blackboxApi, iamApi, caseApi, userApi, jwtBlackboxApi, osintDataUrl, riskApi];

function setupAuthDetails(token) {
    var decoded = jwt_decode(token);
    var email = decoded.email;
    var username = decoded.given_name;
    var cognito_user_id = decoded["cognito:username"];
    email = decoded.email;
    var userRole = decoded["cognito:groups"];
    let payload = {};
    payload.userId = cognito_user_id;
    payload.username = username;
    payload.email = email;
    payload.userRole = userRole;
    // let expirationTime = +response.data.expiresIn * 1000;
    store.commit("SET_AUTH_DETAILS", payload);
    store.commit("auth_request", token);
}

function showToast(error) {
    if (error?.response?.status === 500) {
        Vue.$toast.error("Internal Server Error");
    } else if (error?.response?.status === 401) {
        Vue.$toast.warning("Unauthorised");
    } else if (error?.response?.data?.detail) {
        Vue.$toast.error(error?.response?.data?.detail[0]?.msg);
    }
}

function interceptResponse() {
    endpoints.forEach((endpoint) => {
        endpoint?.interceptors?.response.use(
            function(response) {
                return response;
            },
            function(error) {
                showToast(error);
            }
        );
    });
}

// let headers = async (config) => {
//     config.headers["X-Source"] = `(saas-platform) ${pjson.name}/${pjson.version}`;
//     config.headers["X-User-ID"] = store.getters.getAuthDetails.userId;
//     config.headers["X-CASE-ID"] = store.getters.getCaseId == null ? "" : store.getters.getCaseId;
//     return config;
// };
async function validateAndSetAuth(token) {
    let validatedToken = await validateTokenRemote(token);
    if (validatedToken?.error) {
        if (validatedToken.error.type === "invalid") {
            store.dispatch("logoutUser");
        }
        if (validatedToken.error.type === "expired") {
            try {
                let exstingdecodeToken = decodeToken(token);
                let _token;
                if (exstingdecodeToken?.session_src === "neotaswlp") _token = await fetchWlpRefreshToken(token);
                else _token = await fetchRefreshToken(token);
                let decodedToken = decodeToken(_token);
                store.dispatch("setLoginSession", {decodedToken, token: _token});
            } catch (error) {
                // if refresh token throws an error logout the user
                console.error(error);
                store.dispatch("logoutUser");
            }
        }
    } else {
        // token is valid
        const tokenVerifiedByLocalCert = validateTokenLocal(token);
        if (tokenVerifiedByLocalCert) {
            let decodedToken = decodeToken(token);
            store.dispatch("setLoginSession", {decodedToken, token: token});
            // yay!
        } else {
            // oops !
            store.dispatch("logoutUser");
        }
    }
}

function removeCookiesForIframe() {
    VueCookies.remove("token");
}

let isRedirected = false;
let isPermissionFetched = false;
router.beforeEach(async (to, from, next) => {
    const token = VueCookies.get("token");
    const params = getParamsFromUrl();
    if (token && from.name === null) {
        // if user directly came to any specific url
        await validateAndSetAuth(token);
        // interceptResponse();
        next();
    }
    console.log(to.name);
    if (!token) {
        console.log("no token available");
        // If not logged in
        if (to.name === "Redirect" || to.name === "LoginCallback" || to.name === "Login" || to.name === "forgot-password" || to.name === "update-password" || to.name === "otp-login") {
            next();
        } else {
            // Trying to navigate to another page, send to login but keep next-url.
            if (to.fullPath === "/") VueCookies.set("next-url", "/case-list");
            else VueCookies.set("next-url", to.fullPath);
            next({name: "Login", params: params});
        }
    } else {
        // redirect to onboarding if current account is not onboarded
        if ((to.name === "LoginCallback" || to.name === "Login" || to.name === "Dashboard") && window === window.parent) {
            // Trying to open the Login page, you be crazy!
            // interceptResponse();
            next({name: "View Cases"});
        } else {
            // All systems good to go!
            // interceptResponse();
            next();
        }
        // if (store.getters.getOnBoardingStatus){
        //     // if user moved from one route to other
        //     if (to.name === "LoginCallback" || to.name === "Login" || to.name === "Onboarding") {
        //         // Trying to open the Login page, you be crazy!
        //         next({ name: "View Cases" });
        //     } else {
        //         // All systems good to go!
        //         next();
        //     }
        // } else {
        //     next({name: "Onboarding"})
        // }
    }
    // if (to.name === "research" || to.name === "tools" || to.name === "monitoring") {
    //     await store.dispatch("fetchCaseValid");
    // }
    let case_list_read_only = false;
    if (!case_list_read_only && !isPermissionFetched) {
        await store.dispatch("getCaseListReadOnly");
        case_list_read_only = store.getters.getIsCaseListReadOnly;
        isPermissionFetched = true;
    }
    if (case_list_read_only && !isRedirected) {
        if (to.name === "research") {
            const data = {name: "read-only", params: to.params, query: to.query};
            next(data);
        }
        if (to.name === "tools") {
            const data = {name: "tools-ro", params: to.params, query: to.query};
            next(data);
        }
        if (to.name === "monitoring") {
            const data = {name: "monitoring-ro", params: to.params, query: to.query};
            next(data);
        }
        if (to.name === "methodology") next({name: "methodology-ro"});
        if (to.name === "sources") next({name: "sources-ro"});
        if (to.name === "docBrowser") {
            next(`/ro/${to.params.id}`);
        }
        if (to.name === "Dashboard") next({name: "read-only"});
        else if (to.path.includes("/research/")) {
            if (to.name === "research") {
                const data = {name: "read-only"};
                next(data);
            }
        }

        isRedirected = true;
    }

    // if ((to.name==='research' || to.name==='tools' || to.name==='monitoring' || to.name==='sources') && !isRedirected) {
    //     if (case_list_read_only) {
    //         isRedirected = true
    //         const data = { name: "read-only", params: to.params, query: to.query }
    //         next(data);
    //     } else next()
    // }
});

window.addEventListener(
    "message",
    function(e) {
        var action = e.data?.action;
        var target = e.data?.target;
        let component = router?.apps[0]?.$children[0]?.$children[0]?.$children[2];
        if (action === "refresh-research" && target) {
            if (target !== "map") {
                component.refreshData(target);
            } else component.$refs["graph-panel"].refreshGraph();
        } else if (action === "refresh-tools" && target) {
            component.$router.push(`/research/${store.getters.getCaseId}/tools/${target}`);
        }
    },
    false
);

window.addEventListener("visibilitychange", function(e) {
    let component = router?.apps[0]?.$children[0]?.$children[0]?.$children[2];
    if (component?.currentTopTab === "research") {
        if (component?.currentTab === "map") {
            component?.refreshData(component.currentTab);
        } else component?.$refs["graph-panel"].refreshGraph();
        // } else if (component.currentTopTab === 'tools' && component?.$refs['analysis-tools']?.currentToolSelected) {
        //     component.$router.push(`/research/${store.getters.getCaseId}/tools/${component.$refs['analysis-tools'].currentToolSelected}`)
    }
});
